<template>
  <div class="page">
    <div class="content waterfall">
      <vue-waterfall-easy :imgsArr="articles" :gap="12"  :imgWidth="260" :maxCols="8" ref="waterfall" @scrollReachBottom="getData">
        <div class="mottoinfo " slot-scope="props" :class="{jin:props.value.tj}">
          <div class="tj" v-if="props.value.tj"><i></i></div>
          <div class="tab">
            <i></i>
            <i></i>
            <i></i>
          </div>
          <div class="text">
            <div class="wz" v-html="props.value.nr"></div>
            <div class="who">——{{props.value.who}}</div>
          </div>
        </div>
      </vue-waterfall-easy>
      <!--      <ul class="motto">-->
      <!--        <li v-for="(item,xh) in articles" :key="xh">-->
      <!--          <div class="say">-->
      <!--            <div class="wz" v-html="item.nr"></div>-->
      <!--            <div class="who">——{{item.who}}</div>-->
      <!--          </div>-->
      <!--        </li>-->
      <!--      </ul>-->
    </div>
  </div>
</template>
<script>
import vueWaterfallEasy from 'vue-waterfall-easy'
import {list} from '@/api/api'

export default {
    name: 'articles',
    components: {
        vueWaterfallEasy
    },
    props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            articles: [],
            page: 1,
            totalpage: 1
        }
    },
    created() {
        this.getData()

    },
    methods: {
        getData() {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'motto',
                perpage: 30,
                page: this.page,
                order: ["id DESC"]
            }
            list(data).then((data) => {
                this.articles = this.articles.concat(data.data.list)
                this.page++
                this.totalpage = Math.ceil(data.data.total / data.data.perpage)
                if (this.page > this.totalpage) {
                    this.$refs.waterfall.waterfallOver()
                }
            })

        },
    },
    computed: {}
}
</script>

